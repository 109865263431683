import _styled from "@emotion/styled-base";
import React from 'react';
import { Content } from '@/common/components/content/content-element';
import { mediaquery } from '@/common/utils/styling';
import { jsx as ___EmotionJSX } from "@emotion/core";

var ExhibitionDescriptionContainer = _styled("div", {
  target: "ebx6v8f0",
  label: "exhibition-description_ExhibitionDescriptionContainer"
})("margin-top:2rem;p,ul,ol,a,table{max-width:100%;}", mediaquery.tablet, "{margin-top:4rem;p{font-size:0.9rem;}}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2V4aGliaXRpb24vY29tcG9uZW50cy9leGhpYml0aW9uLWRlc2NyaXB0aW9uL2V4aGliaXRpb24tZGVzY3JpcHRpb24udHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQU1pRCIsImZpbGUiOiIvYnVpbGRzL21vb25hL21vb25hLWZyb250ZW5kL3NyYy9leGhpYml0aW9uL2NvbXBvbmVudHMvZXhoaWJpdGlvbi1kZXNjcmlwdGlvbi9leGhpYml0aW9uLWRlc2NyaXB0aW9uLnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJztcbmltcG9ydCBSZWFjdCwgeyBDU1NQcm9wZXJ0aWVzIH0gZnJvbSAncmVhY3QnO1xuXG5pbXBvcnQgeyBDb250ZW50IH0gZnJvbSAnQC9jb21tb24vY29tcG9uZW50cy9jb250ZW50L2NvbnRlbnQtZWxlbWVudCc7XG5pbXBvcnQgeyBtZWRpYXF1ZXJ5IH0gZnJvbSAnQC9jb21tb24vdXRpbHMvc3R5bGluZyc7XG5cbmNvbnN0IEV4aGliaXRpb25EZXNjcmlwdGlvbkNvbnRhaW5lciA9IHN0eWxlZC5kaXZgXG4gIG1hcmdpbi10b3A6IDJyZW07XG5cbiAgcCxcbiAgdWwsXG4gIG9sLFxuICBhLFxuICB0YWJsZSB7XG4gICAgbWF4LXdpZHRoOiAxMDAlO1xuICB9XG5cbiAgJHttZWRpYXF1ZXJ5LnRhYmxldH0ge1xuICAgIG1hcmdpbi10b3A6IDRyZW07XG4gICAgcCB7XG4gICAgICBmb250LXNpemU6IDAuOXJlbTtcbiAgICB9XG4gIH1cbmA7XG5cbmV4cG9ydCBpbnRlcmZhY2UgRXhoaWJpdGlvbkRlc2NyaXB0aW9uUHJvcHMge1xuICBzdHlsZT86IENTU1Byb3BlcnRpZXM7XG4gIGNsYXNzTmFtZT86IHN0cmluZztcbiAgZGVzY3JpcHRpb24/OiBzdHJpbmc7XG59XG5cbmNvbnN0IEV4aGliaXRpb25EZXNjcmlwdGlvbjogUmVhY3QuRkM8RXhoaWJpdGlvbkRlc2NyaXB0aW9uUHJvcHM+ID0gKHtcbiAgZGVzY3JpcHRpb24sXG4gIHN0eWxlLFxuICBjbGFzc05hbWUsXG59KSA9PiB7XG4gIHJldHVybiAoXG4gICAgPD5cbiAgICAgIHtkZXNjcmlwdGlvbiAmJiAoXG4gICAgICAgIDxFeGhpYml0aW9uRGVzY3JpcHRpb25Db250YWluZXIgc3R5bGU9e3N0eWxlfSBjbGFzc05hbWU9e2NsYXNzTmFtZX0+XG4gICAgICAgICAgPENvbnRlbnQgc291cmNlPXtkZXNjcmlwdGlvbn0gLz5cbiAgICAgICAgPC9FeGhpYml0aW9uRGVzY3JpcHRpb25Db250YWluZXI+XG4gICAgICApfVxuICAgIDwvPlxuICApO1xufTtcblxuZXhwb3J0IHsgRXhoaWJpdGlvbkRlc2NyaXB0aW9uIH07XG4iXX0= */"));

var ExhibitionDescription = function ExhibitionDescription(_ref) {
  var description = _ref.description,
      style = _ref.style,
      className = _ref.className;
  return ___EmotionJSX(React.Fragment, null, description && ___EmotionJSX(ExhibitionDescriptionContainer, {
    style: style,
    className: className
  }, ___EmotionJSX(Content, {
    source: description
  })));
};

export { ExhibitionDescription };